@font-face {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: 500;
	/*//"../fonts/dm-sans-v6-latin-500.eot")*/
	src: url("./fonts/DM-SANS/dm-sans-v6-latin-500.eot"); /* IE9 Compat Modes */
	src: local(""),
		url("./fonts/DM-SANS/dm-sans-v6-latin-500.eot?#iefix")
			format("embedded-opentype"),
		/* IE6-IE8 */ url("./fonts/DM-SANS/dm-sans-v6-latin-500.woff2")
			format("woff2"),
		/* Super Modern Browsers */ url("./fonts/DM-SANS/dm-sans-v6-latin-500.woff")
			format("woff"),
		/* Modern Browsers */ url("./fonts/DM-SANS/dm-sans-v6-latin-500.ttf")
			format("truetype"),
		/* Safari, Android, iOS */
			url("./fonts/DM-SANS/dm-sans-v6-latin-500.svg#DMSans") format("svg"); /* Legacy iOS */
}
@font-face {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: 700;
	src: url("./fonts/DM-SANS/dm-sans-v6-latin-700.eot"); /* IE9 Compat Modes */
	src: local(""),
		url("./fonts/DM-SANS/dm-sans-v6-latin-700.eot?#iefix")
			format("embedded-opentype"),
		/* IE6-IE8 */ url("./fonts/DM-SANS/dm-sans-v6-latin-700.woff2")
			format("woff2"),
		/* Super Modern Browsers */ url("./fonts/DM-SANS/dm-sans-v6-latin-700.woff")
			format("woff"),
		/* Modern Browsers */ url("./fonts/DM-SANS/dm-sans-v6-latin-700.ttf")
			format("truetype"),
		/* Safari, Android, iOS */
			url("./fonts/DM-SANS/dm-sans-v6-latin-700.svg#DMSans") format("svg"); /* Legacy iOS */
}

html {
	box-sizing: border-box;
}
*,
*:before,
*:after {
	box-sizing: inherit;
	font-family: "DM Sans";
}
body {
	margin: 0;
	font-family: -apple-system, DM Sans;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.MuiCollapse-root {
	margin-bottom: 20px;
}
.css-13b987g-MuiButtonBase-root-MuiTab-root.Mui-selected {
	color: yellow !important;
}
.css-1aquho2-MuiTabs-indicator {
	background-color: yellow !important;
}
.css-1vlokwe-MuiTabs-root {
	width: 100%;
}
.css-heg063-MuiTabs-flexContainer {
	width: 100%;
	justify-content: space-evenly;
}
